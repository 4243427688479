/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';

export class News extends Component<any, any> {
  static displayName = News.name;

  render() {
    return (
      <div>
        <h5>Siste nyheter</h5>
        <p>Kommer...</p>
      </div>
    );
  }
}
